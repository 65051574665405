<template>
    <div class="dark:bg-gray-800">
        <Nav />
        <div class="container pt-10">
            <div v-if="loaded">
                <div class="space-y-10">
                    <template v-if="currencies.length">
                        <div
                            class="bg-white shadow overflow-hidden sm:rounded-lg"
                            v-for="{ key, name, symbol } in currencies"
                            :key="key"
                        >
                            <div class="px-4 py-5 sm:px-6 relative">
                                <h3 class="text-lg leading-6 font-medium text-gray-900 relative">
                                    {{ name }} - {{ symbol }}
                                </h3>

                                <router-link
                                    class="absolute right-0 top-0 bottom-0 text-white bg-teal-600 hover:bg-teal-700 flex justify-center align-center pr-12 pl-4"
                                    :to="{ name: 'ViewCurrency', params: { id: key } }"
                                >
                                    <span class="flex items-center text-teal-100">
                                        View
                                    </span>
                                    <span class="absolute right-3 inset-y-0 flex items-center">
                                        <!-- Heroicon name: lock-closed -->
                                        <svg
                                            class="h-5 w-5 text-teal-100"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <div v-else class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6 relative">
                            <h3 class="text-lg leading-6 font-medium text-gray-900 relative">
                                It's time to add some currencies to your account
                            </h3>
                            <router-link
                                class="absolute right-0 top-0 bottom-0 text-white bg-teal-600 hover:bg-teal-700 flex justify-center align-center pr-12 pl-4"
                                :to="{ name: 'AddCurrency' }"
                            >
                                <span class="flex items-center text-teal-100">
                                    Let's go
                                </span>
                                <span class="absolute right-3 inset-y-0 flex items-center">
                                    <svg
                                        class="h-5 w-5 text-teal-100"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="dark:bg-gray-800 min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
            >
                <Loader />
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { firebaseCurrencies } from '@/Firebase'
import { useStore } from 'vuex'

import Loader from '@/components/Loader'
import Nav from '@/components/Nav'

import errorHandler from '@/helpers/errorHandler'

export default {
    components: {
        Loader,
        Nav,
    },

    setup() {
        const state = reactive({
            currencies: [],
            loaded: false,
        })
        const store = useStore()

        const loadCurrencies = async () => {
            try {
                const collection = await firebaseCurrencies
                    .where('uid', '==', store.state.uid)
                    .get()

                state.currencies = await collection.docs
                    .map(doc => ({
                        key: doc.id,
                        ...doc.data(),
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
                state.loaded = true
            } catch (error) {
                errorHandler(error)
            }
        }

        loadCurrencies()

        return {
            ...toRefs(state),
        }
    },
}
</script>

<style lang="scss" scoped></style>
